import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';


import Newsletter from '../../Common/Newsletter';

import footerLogo1 from '../../../assets/img/logo/ayit_digital_logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                        </div>                           
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">IT Services</h3>
                            <ul className="site-map">
                                <li><Link to="#" title="ATMs">ATMs</Link></li>
                                <li><Link to="#" title="Desenvolvimento de Software">Desenvolvimento de Software</Link></li>
                                <li><Link to="#" title="Desenvolvimento Web">Desenvolvimento Web</Link></li>                                
                                <li><Link to="#" title="Analytic Solutions">Analytic Solutions</Link></li>
                                <li><Link to="#" title="Cloud and DevOps">Cloud and DevOps</Link></li>
                                <li><Link to="#" title="IT Product Design">IT Product Design</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                            <h3 className="widget-title">Contato</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">Av. Queiroz Filho, 1700 - 201E</div>
                                    <div className="desc">São Paulo - SP</div>
                                    <div className="desc">Brasil</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <Link to="#">(+55) 11 3834-8025</Link>
                                    </div>                                    
                                </li>                               
                            </ul>
                        </div>                        
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;