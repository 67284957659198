import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style2/hover-img/1.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style2/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style2/hover-img/4.png';
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png';
import hoverIcon5 from '../../assets/img/service/style2/hover-img/5.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import hoverIcon6 from '../../assets/img/service/style2/hover-img/6.png';
import mainIcon2 from '../../assets/img/service/style3/main-img/6.png';
import hoverIcon2 from '../../assets/img/service/style3/hover-img/6.png';

const Service = () => {

    return (
        <div id="rs-service" className="rs-services style3 modify3 rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row md-mb-60">
                    <div className="col-lg-6 mb-60 md-mb-20">
                        <SectionTitle
                            sectionClass="sec-title2 zIndex1"
                            subtitleClass="sub-text white-color"
                            subtitle="Services"
                            titleClass="title testi-title white-color"
                            title="Quais soluções podemos agregar ao seu negócio?"
                        />
                    </div>
                    {/*<div className="col-lg-6 mb-60 md-mb-0">
                        <div className="btn-part text-right mt-60 md-mt-0 md-text-left">
                            <a className="readon started" href="#">Get Started</a>
                        </div>
    </div>*/}
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item paste-bg"
                            prefix="01"
                            mainIcon={mainIcon2}
                            hoverIcon={hoverIcon2}
                            Title="Soluções para Autoatendimento"
                            Text="Intelligent Lockers, Vending Machines, Cash & Check e ATMs de Saque e Recarga de Bilhetes Mifare e QRCodes."
                        />
                    </div>     
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item purple-bg"
                            prefix="02"
                            mainIcon={mainIcon5}
                            hoverIcon={hoverIcon5}
                            Title="Desenvolvimento de Software"
                            Text="Arquitetura, UX e concepção de sistemas sob medida."
                        />
                    </div>    
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item green-bg"
                            prefix="03"
                            mainIcon={mainIcon6}
                            hoverIcon={hoverIcon6}
                            Title="Sustentação de Sistemas"
                            Text="Manutenção de sistemas implantados, correções e melhorias de software. Gestão ou alocação de profissionais."
                        />
                    </div>  
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item paste-bg"
                            prefix="04"
                            mainIcon={mainIcon4}
                            hoverIcon={hoverIcon4}
                            Title="Clould & DevOps"
                            Text="Criação de Plano de Mudança (AWS, Azure e Google). Redução de custos, segurança e sustentabilidade para seus sistemas."
                        />
                    </div>                                          
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item orange-bg"
                            prefix="05"
                            mainIcon={mainIcon1}
                            hoverIcon={hoverIcon1}
                            Title="Outsourcing"
                            Text="Terceirização de equipes. Na sua empresa ou na Ayit, temos expertise para gestão ágil de projetos de TI."
                        />
                    </div>              
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon3}
                            hoverIcon={hoverIcon3}
                            Title="Analytics & SEO"
                            Text="Análise e melhora de estatísticas de tráfego, search engine optimization para melhor posicionamento na web."
                        />
                    </div>
                </div>
            </div>
            <div className="line-inner orange-dark">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
        
    );
}

export default Service;