import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProject from '../../components/Project/SingleProject';


import projectImg1 from '../../assets/img/project/1.jpg';
import projectImg2 from '../../assets/img/project/2.jpg';
import projectImg3 from '../../assets/img/project/3.jpg';
import projectImg4 from '../../assets/img/project/4.jpg';
import projectImg5 from '../../assets/img/project/5.jpg';
import projectImg6 from '../../assets/img/project/6.jpg';

const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                stagePadding: 0,
                nav:true,
            },
            992: {
                items: 2,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style2 black-dark pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="Cases"
                        titleClass="title title2 white-color"
                        title="Alguns dos projetos em que atuamos nos últimos anos..."
                    />
                    <OwlCarousel options={options} >
                        <SingleProject  
                            itemClass="project-item"
                            projectImage={projectImg1} 
                            Title="Autopass"
                            Category="Maior empresa brasileira de sistemas de bilhetagem eletrônica e mobilidade urbana.
                            Desenvolvimento de solução de ATM de Recarga de Bilhetes e QRCodes e Portal Web de Gerenciamento, Distribuição e Monitoramento" 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImg2} 
                            Title="Caixa Econômica Federal"
                            Category="Maior banco público do país. 
                            Desenvolvimento da primeira Plataforma de Automação Bancária do país baseado 100% em software livre." 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImg4} 
                            Title="Itaú Unibanco"
                            Category="Maior banco privado do país. Desenvolvimento de Sistema de Automação Bancária (Caixa, Retaguarda, Autoatendimento e Plataforma de Negócios). " 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImg5} 
                            Title="Integral/SNBC"
                            Category="Fabricante de Tecnologia. Sistemas para Automation, Intelligent Lockers, Vending Machines, Cash & Check e ATMs" 
                        />                        
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImg3} 
                            Title="Banco Mercantil do Brasil" 
                            Category="Banco de varejo.       
                            Desenvolvimento de Sistema de Autoatendimento." 
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImg6} 
                            Title="Banco Sudameris/ABN Anro" 
                            Category="Banco de varejo.       
                            Desenvolvimento do Internet Banking." 
                        />
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project