import React from 'react';

const TopHeaderStyleTwo = () => {

    return (
        <div className="topbar-area style2 hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <ul className="topbar-contact">
                            <li>
                                <i className="fa fa-envelope-open"></i>
                                <a href="/contact"></a>
                            </li>
                            <li>
                                <i className="fa fa-phone"></i>
                                <a href="tel:++1(990)999–5554">+1 (990) 999–5554</a>
                            </li>
                            <li>
                                <i className="fa fa-map-marker"></i>
                                Av. Queiroz Filho, 1700 - São Paulo - SP
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 text-right">
                        <div className="toolbar-sl-share">
                            <ul>
                                
                                <li>
                                    <a href="https://www.linkedin.com/company/ayit" target="_blank"><i className="fa fa-linkedin-square"></i></a>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeaderStyleTwo;