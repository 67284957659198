import React from 'react';
import SectionTitle from '../Common/SectionTitle';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const OnepageContactPart = (props) => {
    const { contactSectionClass, contactSectionTitleClass, contactSubTitleClass, contactBoxClass, submitBtnClass } = props;
    return (
        <div id="rs-contact" className={contactSectionClass ? contactSectionClass : 'rs-contact pt-120 md-pt-80'}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 md-mb-60">
                        <ContactInfo 
                            contactBoxClass = {contactBoxClass}
                        />
                    </div>
                    <div className="col-lg-8 pl-70 lg-pl-50 md-pl-14">
                        <div className="contact-widget onepage-style">
                            <SectionTitle
                                sectionClass="sec-title2 mb-40"
                                subtitleClass={contactSubTitleClass ? contactSubTitleClass : 'sub-text contact mb-14'}
                                subtitle="ENTRE EM CONTATO"
                                titleClass={contactSectionTitleClass ? contactSectionTitleClass : 'title testi-title'}
                                title="Por favor, preencha os dados abaixo"
                            />

                            {/* Contact Form */}
                            <ContactForm 
                                submitBtnClass= {submitBtnClass}
                            />
                            {/* Contact Form */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="map-canvas pt-120 md-pt-80">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.7099327522833!2d-46.736495384517056!3d-23.54293306679819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef8a73c158f4f%3A0xca5847b4e8ac8dc0!2sAv.%20Queiroz%20Filho%2C%201700%20-%20Vila%20Hamburguesa%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005319-000!5e0!3m2!1spt-BR!2sbr!4v1645452284872!5m2!1spt-BR!2sbr&q=rstheme&ie=UTF8&output=embed"></iframe>
            </div>
        </div>
    );
}

export default OnepageContactPart;