import React from 'react';

const ContactForm = (props) => {
    const { submitBtnClass } = props;
    return (
        <form id="contact-form" action="/cgi-sys/formmail.pl">
            <input type="hidden" name="assunto" value="Contato pelo formulário do site"></input>
            <input type="hidden" name="de" value="contatodosite@ayit.com.br"></input>
            <input type="hidden" name="para" value="contato@ayit.com.br"></input>
            <input type="hidden" name="redirecionar" value="/"></input>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" type="text" id="name" name="nome" placeholder="Nome" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="email" id="email" name="email_usuario" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" placeholder="E-Mail" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="tel" id="phone" name="phone" pattern=".{9,11}" minlength="9" maxlength="11" placeholder="Fone" required />
                </div>

                <div className="col-md-6 mb-30">
                    <input className="from-control" type="url" id="website" name="website" pattern="http://.*" placeholder="Website"  />
                </div>

                <div className="col-12 mb-30">
                    <textarea className="from-control" id="message" name="comentarios" minlength="15" placeholder="Sua mensagem" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit">Enviar</button>
            </div>
        </form>
    );

}

export default ContactForm;