import React from 'react';

const TopHeaderStyleThree = () => {

    return (
        <div className="topbar-area style3 hidden-md">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <ul className="topbar-contact">                            
                            
                        </ul>
                    </div>
                    <div className="col-lg-4 text-right">
                        <div className="toolbar-sl-share">
                            <ul>
                                <li>
                                   
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/ayit" target="_blank"><i className="fa fa-linkedin-square"></i></a>
                                </li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopHeaderStyleThree;