import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style3/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style3/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/style3/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/style3/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/style3/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style3/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style3/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style3/hover-img/4.png';

const Sobre = () => {

    return (
        <div id="rs-about" className="rs-about pt-120 md-pt-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <SectionTitle
                            sectionClass="sec-title mb-50"
                            subtitleClass="sub-text style4-bg"
                            subtitle="Sobre a Ayit"
                            titleClass="title pb-20"
                            title="Estamos há mais de 30 anos ajudando empresas a atingirem todo o seu potencial"
                            descClass="desc"
                            description="Criada através da fusão das empresas Fase e Neox, a AYIT  conta com uma experiência de mais de 30 anos no mercado financeiro brasileiro. Participamos dos mais importantes e pioneiros projetos de automação bancária, internet banking e sistemas para empresas brasileiras e estrangeiras."
                        />
                        {/* counter area start */}
                        <CounterHomeFive />
                        {/* counter area end */}
                    </div>
                    <div className="col-lg-7">
                        <div className="rs-services style3 md-pt-50">
                            <div className="row">
                                <div className="col-md-6 pr-10 pt-40 sm-pt-0 sm-pr-0 sm-pl-0">
                                    <SingleServiceTwo
                                        itemClass="services-item mb-20"
                                        mainIcon={mainIcon1}
                                        hoverIcon={hoverIcon1}
                                        Title="Sistemas de Alta Qualidade"
                                        serviceURL="high-quality-code"
                                        Text="At vero eos et accusamus etiusto odio praesentium accusamus."
                                    />
                                    <SingleServiceTwo
                                        itemClass="services-item cyan-bg"
                                        mainIcon={mainIcon2}
                                        hoverIcon={hoverIcon2}
                                        Title="Full-stack Teams"
                                        serviceURL="full-quality-teams"
                                        Text="At vero eos et accusamus etiusto odio praesentium accusamus."
                                    />
                                </div>
                                <div className="col-md-6 pl-10 sm-pr-0 sm-pl-0 sm-mt-20">
                                    <SingleServiceTwo
                                        itemClass="services-item gold-bg mb-20"
                                        mainIcon={mainIcon3}
                                        hoverIcon={hoverIcon3}
                                        Title="Cultura Ágil"
                                        serviceURL="agile-approach"
                                        Text="At vero eos et accusamus etiusto odio praesentium accusamus."
                                    />
                                    <SingleServiceTwo
                                        itemClass="services-item blue-bg"
                                        mainIcon={mainIcon4}
                                        hoverIcon={hoverIcon4}
                                        Title="Sistemas de Alta Qualidade"
                                        serviceURL="high-quality-code"
                                        Text="At vero eos et accusamus etiusto odio praesentium accusamus."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sobre;