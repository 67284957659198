import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import ProgressBar from '../../components/Elements/Progress/ProgressBar';

// Sobre Image
import AboutImage from '../../assets/img/about/about-4.png';

const Sobre = (props) => {

    return (
        <div id="rs-about" className="rs-about style2 pt-110 pb-120 md-pt-75 md-pb-80">
            <div className="image-part">
                <img
                    src={AboutImage}
                    alt="about"
                /> 
            </div>
            <div className="container">
                <div className="row">
                    <div className="offset-lg-5"></div>
                    <div className="col-lg-7 pl-54 md-pl-14">
                        <div className="contact-wrap">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-30"
                                subtitleClass="sub-text style4-bg"
                                subtitle="Sobre a Ayit"
                                titleClass="title pb-20"
                                title="Nossa missão:  promover a expansão de Negócios de Sucesso com o uso da Tecnologia"
                                descClass="desc"
                                description="Apoiamos dezenas de empresas usando as melhores tecnologias para escalar seus negócios digitais."
                            />
                            {/* Section Title End */}
                            <div className="rs-skillbar style1 home4">
                                <ProgressBar
                                    completed={92}
                                    height={7}
                                    boxbg={"#eeeeee"}
                                    figurebg={"#FD6509"}
                                    ProgressBox="progress-box"
                                    ProgressFigure="progress-figure"
                                    ProgressCount="Progress-count"
                                    ProgressLabel="progress-label"
                                    label="Desenvolvimento de Software"
                                    ProgressParent="main-div"
                                />
                                <ProgressBar
                                    completed={80}
                                    height={7}
                                    boxbg={"#eeeeee"}
                                    figurebg={"#03DEA8"}
                                    ProgressBox="progress-box"
                                    ProgressFigure="progress-figure"
                                    ProgressCount="Progress-count"
                                    ProgressLabel="progress-label"
                                    label="Sistemas Bancários"
                                    ProgressParent="main-div"
                                />
                                <ProgressBar
                                    completed={95}
                                    height={7}
                                    boxbg={"#eeeeee"}
                                    figurebg={"#0A99FD"}
                                    ProgressBox="progress-box"
                                    ProgressFigure="progress-figure"
                                    ProgressCount="Progress-count"
                                    ProgressLabel="progress-label"
                                    label="Mobile Apps"
                                    ProgressParent="main-div"
                                />
                                <ProgressBar
                                    completed={78}
                                    height={7}
                                    boxbg={"#eeeeee"}
                                    figurebg={"#F00674"}
                                    ProgressBox="progress-box"
                                    ProgressFigure="progress-figure"
                                    ProgressCount="Progress-count"
                                    ProgressLabel="progress-label"
                                    label="Desenvolvimento Web"
                                    ProgressParent="main-div"
                                />
                            </div>
                            <div className="btn-part mt-54">
                                <Link className="readon started" to="/about"> Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sobre;