import SectionTitle from '../../components/Common/SectionTitle';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style3/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style3/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/style3/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/style3/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/style3/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style3/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style3/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style3/hover-img/4.png';

const Sobre = () => {

    return (
        <div id="rs-about" className="rs-about pt-120 pb-120 md-pt-70 md-pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <SectionTitle
                            sectionClass="sec-title mb-50"
                            subtitleClass="sub-text white-color style4-bg"
                            subtitle="Sobre a Ayit"
                            titleClass="title white-color pb-20"
                            title="Estamos há mais de 30 anos ajudando empresas a atingirem todo o seu potencial"
                            descClass="desc white-color"
                            description="Criada na fusão das empresas Fase e Neox, a Ayit Digital conta com uma grande vivência no mercado brasileiro. Participamos dos mais importantes e pioneiros projetos e sistemas para as maiores empresas brasileiras e estrangeiras em seus segmentos."
                        />
                        {/* counter area start */}
                        <CounterHomeFive 
                            counterSectionClass="rs-counter style3 white-color"
                        />
                        {/* counter area end */}
                    </div>
                    <div className="col-lg-7">
                        <div className="rs-services style3 modify3 md-pt-50">
                            <div className="row">
                                <div className="col-md-6 pt-40 sm-pt-0">
                                    <SingleServiceTwo
                                        itemClass="services-item orange-bg mb-20"
                                        mainIcon={mainIcon1}
                                        hoverIcon={hoverIcon1}
                                        Title="Sistemas de Alta Qualidade"
                                        Text="Experiência em desenvolvimento de software para Bancos, Fintechs, Real State Techs, Mobilidade Urbana, Autoatendimento (ATMs), Mobile, Portais e Aplicações Web Institucionais."
                                    />
                                    <SingleServiceTwo
                                        itemClass="services-item cyan-bg"
                                        mainIcon={mainIcon2}
                                        hoverIcon={hoverIcon2}
                                        Title="Full-Stack Teams"
                                        Text="Equipes de arquitetura e desenvolvimento com vivência nas melhores tecnologias utilizadas pelo mercado."
                                    />
                                </div>
                                <div className="col-md-6 sm-mt-20">
                                    <SingleServiceTwo
                                        itemClass="services-item gold-bg mb-20"
                                        mainIcon={mainIcon3}
                                        hoverIcon={hoverIcon3}
                                        Title="Cultura Ágil"
                                        Text="Modelo de trabalho interativo que se adapta perfeitamente ao seu tamanho, necessidade e expectativas, trazendo produtividade, resultados e otimização de custos."
                                    />
                                    <SingleServiceTwo
                                        itemClass="services-item blue-bg"
                                        mainIcon={mainIcon4}
                                        hoverIcon={hoverIcon4}
                                        Title="Segurança da Informação"
                                        Text="Desenvolvimento de camadas, frameworks e dispositivos para soluções seguras."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sobre;