import SectionTitle from '../../components/Common/SectionTitle';
import AppointmentForm from '../../components/Appointment/Appointment';

// WhyChooseUs Images
import iconOne from '../../assets/img/choose/icons/style2/1.png';
import iconTwo from '../../assets/img/choose/icons/style2/2.png';
import iconThree from '../../assets/img/choose/icons/style2/3.png';


const WhyChooseUs2 = () => {

    return (
        <div className="rs-why-choose style2 gray-bg rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7 md-mb-30">
                        <SectionTitle
                            sectionClass="sec-title mb-40"
                            subtitleClass="sub-text style4-bg left"
                            subtitle="Consulte-nos sem compromisso"
                            titleClass="title pb-20"
                            title="Fale com nosso time."
                            descClass="desc fixed-width"
                            description="Deixe-nos saber mais sobre suas necessidades em TI "
                        />
                        <div className="services-wrap mb-24">
                            <div className="services-icon">
                                <img
                                    src={iconOne}
                                    alt="Choose Icon"
                                />
                            </div>
                        </div>
                        <div className="services-wrap mb-24">
                            <div className="services-icon">
                                <img
                                    src={iconTwo}
                                    alt="Choose Icon"
                                />
                            </div>
                            <div className="services-text">
                                <h3 className="title"><a href="#">Fone</a></h3>
                                <p className="services-txt">11 3834-8025</p>
                            </div>
                        </div>
                        <div className="services-wrap">
                            <div className="services-icon">
                                <img
                                    src={iconThree}
                                    alt="Choose Icon"
                                />
                            </div>
                            <div className="services-text">
                                <h3 className="title"><a href="#">Office</a></h3>
                                <p className="services-txt">Av. Queiroz Filho, 1700 - Vila Hamburguesa<br></br>São Paulo - SP</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="rs-contact mod1">   
                            <AppointmentForm 
                                formTitle="Agende uma reunião"
                                formBtnClass="submit-btn orange-btn"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="line-inner">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    );
}

export default WhyChooseUs2;