import React from 'react';
import SectionTitle from '../Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle="Fale com a Ayit"
                titleClass="title white-color"
                title="Agende uma reunião sem compromisso"
            />
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-phone"></i>
                </div>
                <div className="address-text">
                    <span className="label">Fone:</span>
                     <a href="tel:+551138348025">11 3834-8025</a>
                </div>
            </div>
            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">
                    <span className="label">Endereço:</span>
                    <div className="desc">Av. Queiroz Filho, 1700 - sala 201 E</div>
                    <div className="desc">Vila Hamburguesa - São Paulo - SP</div>
                </div>
            </div>
        </div>
    );

}

export default ContactInfo;